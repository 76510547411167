import React from 'react';

import '../styles/solid-line.scss';

interface Props {
  centered?: boolean;
  modeClassName?: 'dark' | 'blue' | 'primary' | 'light';
}

const SolidLine: React.FC<Props> = ({ modeClassName = 'dark', centered }) => (
  <div
    className={`solid-line ${modeClassName} ${
      centered ? 'solid-line-centered' : ''
    }`}
  ></div>
);

export default SolidLine;
