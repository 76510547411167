import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../styles/imprint-page.scss';
import { useIntl } from 'gatsby-plugin-intl';
import SolidLine from '../components/solid-line';

const ImprintPage: React.FC = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'imprint.SEO_TITLE' })} />

      <main id="imprint-page">
        <div className="container">
          <section className="section-container">
            <div className="col">
              <article className="group">
                <h4>Impressum</h4>
                <SolidLine />

                <p>MIKINO</p>
                <p>
                  <a
                    href="https://goo.gl/maps/akXfUwtTFZhAH4xX6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Nauener Str. 11, 13581 Berlin
                  </a>
                </p>
                <p>Inhaber: Mikita Nowak</p>
                <p>Umsatzsteuer-Identifikationsnummer: 19/458/01716</p>
                <p>
                  E-Mail:{' '}
                  <a className="text-primary" href="mailto:info@mikino.de">
                    info@mikino.de
                  </a>
                </p>
              </article>
            </div>
            <div className="col"></div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default ImprintPage;
